.post__detail {
  grid-row: 1 / 3;
  grid-column: 1 / -1;
  padding: 2rem;

  &__container {
    display: flex;
    //background-color: rgb(139, 131, 131);
    width: 100vw;
    height: 100vh;
    color: white;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 37.5em) {
      display: flex;
      flex-direction: column;
      height: auto;
    }

    &__content {
      display: flex;
      flex-direction: column;

      width: 50%;
      height: 100%;
      align-items: center;
      padding: 0.8rem;
      @media (max-width: 37.5em) {
        width: 90%;
      }

      &__image-container {
        width: 100%;
        height: 60%;
        border-radius: 1rem;

        &__image {
          width: 100%;
          height: 100%;
          border-radius: 1rem;
        }
      }
      &__header__container {
        width: 100%;
        height: 50%;
        border-radius: 1rem;
        margin-top: 0.8rem;
        //background-color: #55778b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__header {
          margin-top: 0.8rem;
          width: 100%;
          height: 20%;
          background-color: #55778b;
          display: flex;
          justify-content: space-between;
          border-radius: 1rem;
          &__userinfo {
            width: 20rem;
            height: 100%;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            padding: 1rem;
            //background-color: rgb(118, 26, 26);

            &__picture {
              width: 5rem;
              height: 5rem;
              border: 0.1rem solid #ebe1c1;
              border-radius: 50%;
              padding: 0.6rem;
              margin-right: 1.8rem;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            &__right {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;

              &__name {
                font-size: 1.2rem;
                cursor: pointer;
              }
            }
          }
          &__options {
            //background-color: rgb(43, 104, 43);
            width: 10rem;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 1rem 1rem 0;
            &__icons {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: space-evenly;

              &__update-icon {
                color: #fff;
                font-size: 2rem;
              }

              &__delete-icon {
                color: #fff;
                font-size: 2rem;
                cursor: pointer;
              }
            }
          }
        }
        &__header__text {
          margin-top: 0.8rem;
          width: 100%;
          height: 75%;
          border-radius: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 0.8rem;
          &__title {
            background-color: #55778b;
            width: 100%;
            height: 15%;
            border-radius: 1rem;
            padding: 1.2rem;
            font-size: 2.4rem;
            display: flex;
            align-items: center;
            letter-spacing: 0.2rem;
            margin-bottom: 0.8rem;
          }
          &__description {
            width: 100%;
            height: 90%;
            background-color: #55778b;
            padding: 1.2rem;
            font-size: 2rem;
            letter-spacing: 0.1rem;
            border-radius: 1rem;
          }
        }
      }
    }
    &__comment {
      //background-color: beige;
      width: 30%;
      height: 100%;
      color: black;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 5rem;
      @media (max-width: 37.5em) {
        width: 80%;
      }
      //padding: 2rem;
      &__form {
        width: 100%;
        height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__list {
        margin-top: 2.4rem;
        width: 100%;
        height: 100%;
      }
    }
  }
}
