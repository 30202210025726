.select {
  width: 40rem;
  // height: 4.8rem;
  font-size: 1.6rem;
  border-radius: 1rem;
  border: 0.2rem solid #ebe1c1;
  margin-bottom: 2.4rem;
  &:focus {
    outline: none;
  }
  @media (max-width: 37.5em) {
    width: 25rem;
  }
}
.select-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.select-label {
  position: absolute;
  top: -2.5rem;
  font-size: 1.6rem;
}
