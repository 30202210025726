.postList {
  grid-row: 1 / 3;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  //grid-auto-flow: column;
  grid-gap: 3rem;
  margin-top: 3.2rem;
  padding: 2rem;
}
