.post-list {
  grid-row: 1 / 3;
  grid-column: 1 / -1;

  &__cta__add {
    padding: 2rem;
    color: #ebe1c1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    width: 30rem;
    border: 0.1rem solid #ebe1c1;
    border-radius: 1rem;
    margin: 2rem 0 0 2rem;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #55778b;
    }

    &__icon {
      margin-right: 1rem;
    }
  }
  &__title-not-found {
    color: #ebe1c1;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
}

.post-list__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  grid-gap: 3rem;
  margin-top: 3.2rem;
  padding: 2rem;
}
