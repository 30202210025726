.mobile-navigation {
  display: none;
  @media (max-width: 37.5em) {
    display: flex;
    font-size: 4rem;
    cursor: pointer;
    z-index: 20;
  }

  &__list {
    list-style-type: none;
    &--item {
      color: white;
      &-active {
        color: #042539;
      }
      &-inactive {
        color: white;
      }
    }
  }
}

.mobile-navigation__container {
  background-color: #55778b;
  position: absolute;
  top: 10rem;
  right: 0;
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem 0 0 1rem;

  &__nav-auth {
    a {
      padding: 0.8rem;
      margin: 0 0.8rem;
      transition: all 0.4s ease-in-out;
      &:hover {
        color: #55778b;
      }
    }
    :last-child {
      margin-right: 3.2rem;
      border: 0.2rem solid #ebe1c1;
      color: #55778b;
      border-radius: 1rem;
      background: #fff;
      transition: all 0.4s ease-in-out;
      &:hover {
        background: #55778b;
        color: #fff;
      }
      .active {
        background: #55778b;
        color: #fff;
      }
    }
  }
}
