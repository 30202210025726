.landing {
  grid-area: Main;
  width: 100%;
  height: auto;
  @media (max-width: 37.5em) {
    display: flex;
    flex-direction: column;
  }

  &__container {
    position: relative;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &__video {
      height: 100vh;
      width: 100vw;
      z-index: -10;
      object-fit: cover;
      position: fixed;
      top: 0;
      left: 0;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media (min-aspect-ratio: 16/9) {
        width: 100%;
        height: auto;
      }
      @media (max-aspect-ratio: 16/9) {
        width: auto;
        height: 100%;
      }
    }
    &__content {
      color: #fff;
      //background-color: yellow;
      width: 100%;
      height: 85%;
      display: flex;

      &__text {
        //background-color: blue;
        //height: 100%;
        width: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 3.2rem;
        letter-spacing: 0.2rem;
        line-height: 6rem;
        &__main {
          //background-color: red;
          width: 100%;
          padding: 3.2rem;
          text-align: center;
          font-family: futura-pt, sans-serif;
          font-weight: 400;
          font-style: normal;

          &__title {
            text-transform: uppercase;
            letter-spacing: 0.5rem;
            font-size: 8rem;
            margin-bottom: 3rem;
            font-family: futura-pt-condensed, sans-serif;
            font-weight: 500;
            font-style: normal;
          }
        }
        &__register-cta {
          width: 30rem;
          height: 6rem;

          display: flex;
          align-items: center;
          justify-content: center;
          border: 0.2rem solid #ebe1c1;
          border-radius: 1rem;
          background-color: rgba(255, 255, 255, 0.2);
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          a {
            color: #fff;
          }
          &:hover {
            background-color: rgba(255, 255, 255, 0.4);
          }
        }
      }
      &__images {
        //background-color: orange;
        height: 100%;
        width: 50%;
        padding: 1.6rem;
        position: relative;
        //background-color: yellow;
        &__container {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-direction: column;
          width: 100%;
          height: 100%;

          &__image {
            //background-color: aqua;
            width: 50rem;
            height: 70rem;
            border-radius: 1rem;
            //background-color: orange;
            @media (max-width: 37.5em) {
              display: none;
            }

            &__image1 {
              position: absolute;
              left: 10%;
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 1rem;
            }
            // &:not(last-child) {
            //   margin-top: 1.2rem;
            // }
          }
          &__image2 {
            background-color: red;
          }
          &__image3 {
            background-color: purple;
          }
        }
      }
    }
  }
}

// #video {
//   width: 100%;
//   height: 100%;
// }
