//------------------------------------------
// Mixin for Responsive Design
//------------------------------------------

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    }
  }
}

//------------------------------------------
// Mixin for Centering
//------------------------------------------

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
