//------------------------------------------
// Colors
//------------------------------------------
$color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

//Choosen Color Pallete
$prussian-blue: #042539;
$dark-electric-blue: #55778b;
$dutch-white: #ebe1c1;
$new-york-pink: #e58c8a;
$eggplant: #533c45;

//------------------------------------------
// Font Sizes
//------------------------------------------
$default-font-size: 1.6rem;
