.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}
.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
  @include respond(tab-port) {
    margin-bottom: 3rem !important ;
  }
}
.u-margin-bottom-big {
  margin-bottom: 8rem !important;

  @include respond(tab-port) {
    margin-bottom: 5rem !important;
  }
}

.u-margin-top-big {
  margin-top: 8rem !important;
}
