.upload-photo {
  grid-column: 3 / 7;
  grid-row: 1 / 3;
  margin-top: 8rem;
  &__container {
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;

    &__form {
      background: #386986;
      border-radius: 1rem;
      width: 50rem;
      height: 40rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media (max-width: 37.5em) {
        width: 30rem;
      }
      h1 {
        color: #fff;
        letter-spacing: 0.2rem;
        margin-bottom: 6.4rem;
      }
      button {
        width: 80%;
        height: 4.8rem;
        border-radius: 1rem;
        cursor: pointer;
        border: none;
        color: #fff;
        background-color: #55778b;
        transition: all 0.3s ease-in-out;
        font-size: 1.6rem;
        &:hover {
          color: fff;
          background-color: #486b80;
          //border: 0.2rem solid #ebe1c1;
        }
        &:active {
          transform: scale(0.98);
        }
      }
    }
  }
}
