.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: futura-pt, sans-serif;
  font-size: 1.8rem;
  color: #fff;
  text-transform: uppercase;
  padding: 3.2rem;
  letter-spacing: 0.3rem;
  white-space: nowrap;

  .logo-container {
    width: 8rem;
    height: 8rem;
    margin-right: 3.2rem;

    .logo-img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

.active {
  color: #55778b;
}

.inactive {
  color: #fff;
}
