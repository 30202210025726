.login {
  grid-column: 3 / 7;
  grid-row: 1 / 3;
  margin-top: 8rem;
}

.login__form__container {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;

  h1 {
    color: #fff;
    letter-spacing: 0.2rem;
    margin-bottom: 6.4rem;
  }

  .login__form {
    background: #386986;
    border-radius: 1rem;
    width: 50rem;
    height: 50rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__text__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 6.4rem;
      align-items: center;
      position: relative;
      margin-bottom: 2.4rem;

      label {
        position: absolute;
        left: 5.5rem;
        top: -2.5rem;

        font-size: 1.6rem;
      }

      input {
        width: 80%;
        height: 4.8rem;
        padding: 0.8rem;
        font-size: 1.6rem;
        border-radius: 1rem;
        border: 0.2rem solid #ebe1c1;
        &:focus {
          outline: none;
        }
      }
    }
    button {
      width: 80%;
      height: 4.8rem;
      border-radius: 1rem;
      cursor: pointer;
      border: none;
      color: #fff;
      background-color: #55778b;
      transition: all 0.3s ease-in-out;
      font-size: 1.6rem;
      &:hover {
        color: fff;
        background-color: #486b80;
        //border: 0.2rem solid #ebe1c1;
      }
      &:active {
        transform: scale(0.98);
      }
    }
    .reset-password {
      margin-top: 1.6rem;
      width: 80%;
      color: #dbe2e7;
      font-size: 1.4rem;
      color: #ebe1c1;

      a:visited {
        color: #533c45;
      }
    }
  }
}
