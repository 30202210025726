.nav {
  display: flex;
  align-items: center;
  @media (max-width: 37.5em) {
    display: none;
  }

  // :first-child {
  //   margin-left: 5rem;
  //}
  .nav-links {
    display: flex;
    justify-content: center;
    a {
      margin: 0 2.4rem;
      transition: all 0.4s ease-in-out;
      &:hover {
        color: #55778b;
      }
    }
  }
}
